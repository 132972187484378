<template>
  <div
    class="avatar-panel-root"
    :class="`avatar-panel-root--${size} ${dropdownClass}`"
  >
    <div class="flex flex-wrap items-center justify-end sm:justify-start">
      <div class="mr-[10px] sm:mr-[30px] hidden sm:inline-flex" v-if="hasSupport">
        <div
          @click="zendeskRedirectHandler()"
          class="relative text-base leading-none transition duration-300 cursor-pointer text-blue-700 hover:text-blue-500 dark:text-white dark:hover:text-blue-500"
        >
          <span class="wrapper-icon">
            <SvgIcon
              class="text-gray-600 dark:text-blue-500 mr-[4px]"
              icon="support"
            ></SvgIcon>
          </span>
          support
        </div>
      </div>
      <div
        :class="`avatar-icon ${ bodyOpenClass } sm:mr-[10px] flex items-center`"
        @click="toggleBodyOpen"
      >
        <!-- <div class="text-gray-600 dark:text-white cursor-pointer shadow-generaBoxShadowLightTheme w-[36px] h-[36px] border-[6px] border-gray-200 rounded-full sm:w-[46px] sm:h-[46px] xl:w-[60px] xl:h-[60px] dark:border-blue-500 dark:shadow-generalShadow"> -->
        <div class="text-gray-600 dark:text-white cursor-pointer shadow-generaBoxShadowLightTheme w-[36px] h-[36px] border-[6px] border-gray-200 rounded-full w-[36px] h-[36px] sm:w-[46px] sm:h-[46px] dark:border-blue-500 dark:shadow-generalShadow">
          <SvgIcon
            icon="user-white"
            class="avatar-icon-user relative"
          ></SvgIcon>
        </div>
      </div>
      <div
        class="ma-auto sm:order-[0] sm:ma-0 sm:flex sm:flex-col sm:items-center"
        :class="hasCustomStyle"
      >
        <template v-if="userInfo">
          <div class="text-blue-700 text-2xs leading-none sm:mb-[6px] sm:text-xs dark:text-white">{{ userInfo.email }}</div>
          <div class="inline-flex flex-wrap">
            <component
              :is="isVerified || isBusiness ? 'div' : 'router-link'"
              to="/verification"
              class="inline-flex items-center rounded-[1.6px] mr-[10px] bg-blue-300 text-white text-[12px] leading-none px-[10px] py-[2px] sm:text-xs dark:bg-blue-500/[.10] dark:text-blue-500"
            >
              <SvgIcon
                class="mr-[4px]"
                icon="verified"
                v-if="isVerified"
              ></SvgIcon>
              {{ accountTypeTitle }}
            </component>
            <div
              v-if="hasExit"
              class="cursor-pointer hidden sm:inline-flex items-center rounded-[1.6px] bg-transparent transition duration-300 text-blue-300 text-xs shadow-none leading-none font-medium px-[10px] py-[2px] border-[2px] border-blue-300 dark:text-blue-800 dark:bg-blue-500 dark:hover:bg-blue-400 dark:shadow-generalShadow dark:border-none dark:text-blue-500"
              @click="handleLogout"
            >
              <SvgIcon
                class="mr-[4px]"
                icon="exit"
              ></SvgIcon>
              Exit
            </div>
          </div>
        </template>
      </div>
      <div :class="`avatar-panel-body ${ bodyDropdownClass } ${ bodyOpenClass } flex items-center`">
        <div class="avatar-panel-more">
          <div
            @click="zendeskRedirectHandler()"
            class="inline-flex items-center justify-center shadow-none border text-blue-500 border-blue-500 rounded-[1.6px] mb-[5px] md:mr-[5px] md:mb-0 bg-blue-500/[.21] text-2xs py-[2px] px-[10px] dark: "
          >
            <span class="wrapper-icon mr-[4px]">
              <SvgIcon
                icon="support"
              ></SvgIcon>
            </span>
            support
          </div>
        <div
          v-if="hasExit"
          class="inline-flex items-center justify-center shadow-none rounded-[1.6px] text-blue-500 bg-blue-500/[.21] text-2xs py-[2px] px-[10px] min-w-[60px]"
          @click="handleLogout"
        >
          <SvgIcon
            class="mr-[4px]"
            icon="exit"
          ></SvgIcon>
          Exit
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { iconSupport, iconVerified, iconExit } from '@/assets/icons/common';
import SvgIcon from "@/components/_common/SvgIcon";

export default {
  data: function () {
    return {
      dropdownClass: this.dropdown ? 'avatar-panel-root--dropdown' : '',
      bodyDropdownClass: this.dropdown ? 'avatar-panel-dropdown' : '',
    }
  },
  props: {
    hasCustomStyle: {
      type: String,
      default: ''
    },
    hasExit: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: 'small',
    },
    dropdown: {
      type: Boolean,
      default: false,
    },
    hasSupport: {
      type: Boolean,
      default: false,
    },
    bodyOpen: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    iconSupport,
    iconVerified,
    iconExit,
    SvgIcon
  },
  computed: {
    ...mapState('user', ['userInfo']),
    bodyOpenClass() {
      return this.bodyOpen ? 'open' : '';
    },
    isVerified() {
      return this.userInfo.account_type == 'VERIFIED';
    },
    isBusiness() {
      return this.userInfo.account_type == 'BUSINESS';
    },
    accountTypeTitle( { userInfo: { account_type } } = this ) {
      let titles = {
        NOT_VERIFIED: 'Not Verified',
        BUSINESS: 'Business',
        VERIFIED: 'Verified',
      };
      return titles[account_type] || 'Not Verified';
    },
  },
  methods: {
    ...mapActions('app', ['setMobileMenuShown']),
    handleLogout() {
      this.$emit('logout');
    },
    toggleBodyOpen() {
      this.$emit('updateBodyOpen', !this.bodyOpen);
      if (!this.bodyOpen) {
        this.setMobileMenuShown(false);
      }
    },
    zendeskRedirectHandler() {
      this.$router.push({ name: 'zendesk', query: { brand_id: 'true' } });
    },
  },
};
</script>

<style lang="scss" scoped>
@import './AvatarPanel.scss';
</style>
