<template>
  <div class="Navigation_Menu text-left bg-[#FBFBFB] py-20 px-28 flex flex-col dark:bg-blue-700">
    <div
      class="Navigation_MenuItem"
      :class="{
        'Navigation_MenuItem--hasChildren': item.children,
        'Navigation_MenuItem--open': item.visible,
        'Navigation_MenuItem--hidden': !item.visible,
      }"
      v-for="(item, index) in items"
      :key="item.path"
      @click="openMenuItem(index)"
      ref="MenuItem"
    >
      <div class="Navigation_MenuItemIcon text-blue-300 dark:text-blue-500 text-[16px]">
        <SvgIcon
          class="!h-[18px] !w-[18px]"
          :path="true"
          :icon="item.icon"
        ></SvgIcon>
      </div>
      <router-link
        class="Navigation_MenuItemLink"
        :to="item.path"
      >
        {{ item.name }}
      </router-link>
      <i 
        class="Navigation_MenuItemArrow"
        v-if="item.children"
        @click.stop="toggleMenuItem(index)"
      ></i>
      <div
        class="Navigation_MenuItemSubMenu"
        v-if="item.children"
      >
        <div
          class="Navigation_MenuItem"
          v-for="itemChild in item.children"
          :key="itemChild.path"
        >
          <router-link
            class="Navigation_MenuItemLink"
            :to="item.path + itemChild.path"
          >
            {{ itemChild.name }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/_common/SvgIcon";
export default {
  components: {
    SvgIcon
  },
  props: {
    menuItem: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      items: [],
    }
  },
  watch: {
    menuItem() {
      this.setItems()
    }
  },
  mounted() {
    this.setItems()
  },
  methods: {
    setItems() {
      this.items = this.menuItem.map(item => ({ ...item, visible: this.$route.path.indexOf(item.path) === 0 }))
    },
    toggleMenuItem(index) {
      this.items[index].visible = !this.items[index].visible
    },
    openMenuItem(index) {
      this.items[index].visible = true
    }
  },
};
</script>

<style lang="scss">
@import './NavigationMenu.scss';
</style>