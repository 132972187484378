<template>
  <div class="navigation-root h-screen overflow-auto bg-white dark:bg-primary-nav-bg">
    <div class="bg-white dark:bg-blue-700" @click="handleCloseMenu">
      <a class="!text-[#22272F] dark:!text-blue-500 min-h-[90px] flex-col justify-center items-center no-underline hidden bg-white dark:bg-blue-800 xl:flex" href="/">
        <SvgIcon
          class="!w-[120px] !h-[22px]"
          icon="financeLogoBlue"
        ></SvgIcon>
      </a>
      <div class="h-full">
        <div class="h-[42px] relative flex justify-center items-center xl:h-[50px] bg-[#FBFBFB] dark:bg-blue-500/[.04] border-b-[1px] border-gray-600/[.21] dark:border-[#133545]">
          <img src="@/assets/img/_common/menuTitleKauri.svg" alt="logo" class="2xl:w-auto 2xl:h-[10px] hidden dark:block"/>
          <img src="@/assets/img/_common/menuTitleKauriLightMode.svg" alt="logo" class="2xl:w-auto 2xl:h-[10px] dark:hidden"/>
        </div>
        <NavigationMenu
          :menuItem="navLinksComputed"
          @toggleMenuItem="toggleMenuItem"
        />
        <div class="h-[42px] relative flex justify-center items-center xl:h-[50px] bg-[#FBFBFB] dark:bg-blue-500/[.04] border-b-[1px] border-t-[1px] border-gray-600/[.21] dark:border-[#133545]">
          <img src="@/assets/img/_common/menuTitleFund.svg" alt="logo" class="hidden dark:block" />
          <img src="@/assets/img/_common/menuTitleFundLightMode.svg" alt="logo" class="dark:hidden" />
        </div>
        <NavigationMenu 
          :menuItem="navFundComputed"
        />
        <div
          class="
            h-[42px] relative bg-[#FBFBFB] border-b-[1px] border-t-[1px] border-gray-600/[.21] dark:border-[#133545] flex justify-center items-center xl:h-[50px] dark:bg-blue-500/[.04]
            navigation-container-menu-title--sml
            navigation-container-menu-title--top
            navigation-container-menu-title--bottom
            navigation-container-menu-title--soon
          "
        >
          <img src="@/assets/img/_common/menuTitlePay.svg" alt="logo" class="hidden dark:block" />
          <img src="@/assets/img/_common/menuTitlePayLightMode.svg" alt="logo" class="dark:hidden" />
        </div>
      </div>
      <NavigationMenu 
        :menuItem="navSettingsComputed"
      />
    </div>
  </div>
</template>

<script>
import { NAV_LINKS, NAV_LINKS_PROVIDERS, NAV_LINKS_FUND, NAV_LINKS_SETTINGS } from './constants';
import { mapGetters } from "vuex"
import NavigationMenu from './NavigationMenu/NavigationMenu.vue';
import SvgIcon from "@/components/_common/SvgIcon";

export default {
  components: {
    NavigationMenu,
    SvgIcon
  },
  data: () => ({
    navLinks: NAV_LINKS,
  }),
  computed: {
    ...mapGetters('app', ['isMobile']),
    ...mapGetters('user', ['getTypeUserInfo']),
    navLinksComputed() {
      if(this.getTypeUserInfo) {
        const array = this.getTypeUserInfo === "BUSINESS" ? this.navLinks : this.navLinks.filter(item => item.name !== "Invoicing")
        return [...array, ...NAV_LINKS_PROVIDERS];
      }
    },
    navFundComputed() {
      return [...NAV_LINKS_FUND];
    },
    navSettingsComputed() {
      return [...NAV_LINKS_SETTINGS];
    },
  },
  methods: {
    handleCloseMenu() {
      this.$emit('closeMenu');
    },
    toggleMenuItem(item) {
      if (item.visible == undefined) item.visible = false
      item.visible = !item.visible
      console.log(item)
    },
  },
};
</script>

<style lang="scss">
@import './Navigation.scss';
</style>
