<template>
  <section class="MobileBackButton_root" v-if="isComponentVisible">
    <div class="MobileBackButton_wrapper" @click="goBack">
      <div class="MobileBackButton_icon">
        <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0.123418 4.15506C-0.0411392 4.31962 -0.0411392 4.60759 0.123418 4.81329L4.23734 8.92722C4.4019 9.09177 4.68987 9.09177 4.89557 8.92722C5.06013 8.76266 5.06013 8.47468 4.89557 8.26899L1.60443 4.97785H12.5475C12.7943 4.97785 13 4.77215 13 4.52532C13 4.27848 12.7943 4.07278 12.5475 4.07278H1.56329L4.85443 0.781646C5.01899 0.617089 5.01899 0.329114 4.85443 0.123418C4.68987 -0.0411392 4.36076 -0.0411392 4.1962 0.123418L0.123418 4.15506Z"
            fill="#2AF3F3"
            stroke="#2AF3F3"
            stroke-width="0.2"
          />
        </svg>
      </div>
      <div class="MobileBackButton_title">{{ mobileBackButtonTitle }}</div>
    </div>
  </section>
</template>

<script>
export default {
  data: function () {
    return {
      buttonData: [
        { pathName: 'providers.dashboard.create', title: 'back to dashboard', link: 'providers.dashboard' },
        { pathName: 'providers.router', title: 'back to dashboard', link: 'providers.dashboard' },
        { pathName: 'exchange.wallet.withdraw', title: 'back to wallet', link: 'exchange.wallet' },
        { pathName: 'exchange.wallet.deposit', title: 'back to wallet', link: 'exchange.wallet' },
      ],
    };
  },
  computed: {
    isComponentVisible() {
      return this.buttonData.some(el => el.pathName == this.$route.name);
    },
    goBackRouteName() {
      return this.buttonData.find(el => el.pathName == this.$route.name)?.link || 'exchange.dashboard';
    },
    mobileBackButtonTitle() {
      return this.buttonData.find(el => el.pathName == this.$route.name)?.title || 'Back';
    },
  },
  methods: {
    goBack() {
      // this.$router.push({ name: 'providers.dashboard' });
      this.$router.push({ name: this.goBackRouteName });
    },
  },
};
</script>

<style lang="scss">
@import './MobileBackButton.scss';
</style>
