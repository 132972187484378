<template>
  <header class="bg-white dark:bg-header-gradient py-[8px]">
    <div class="flex justify-between items-center px-[10px] relative z-[999] md:pr-[15px] md:pl-[21px]">
      <div
        class="Header_menu"
        :class="{ 'Header_menu--open': mobileMenuShown }"
        @click="toggleMenu"
      >
        <i class="Header_menu_icon_hamburger"><span></span></i>
      </div>
      <Switcher
        class="hidden md:inline-flex"
      ></Switcher>
      <AvatarPanel
        @updateBodyOpen="updateBodyOpen"
        @logout="handleLogout"
        :bodyOpen="bodyOpen"
        :hasSupport="true"
        :dropdown="true"
        hasCustomStyle="order-[-1]"
      />
    </div>
  </header>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import AvatarPanel from './AvatarPanel/AvatarPanel';
import Switcher from "@/components/_common/Switcher/Switcher";
export default {
  components: {
    AvatarPanel,
    Switcher
  },
  data: () => ({
    bodyOpen: false,
  }),
  computed: {
    ...mapGetters('app', ['isMobile']),
    ...mapState('app', ['mobileMenuShown']),
  },
  methods: {
    ...mapActions('user', ['logoutAction']),
    ...mapActions('app', ['toggleMobileMenu']),
    toggleMenu() {
      this.toggleMobileMenu();
      if (this.bodyOpen) {
        this.bodyOpen = false
      }
    },
    updateBodyOpen(bodyOpen) {
      this.bodyOpen = bodyOpen
    },
    handleLogout() {
      this.logoutAction();
    },
  },
};
</script>

<style lang="scss" scoped>
@import './Header.scss';
</style>
