<template>
  <svg :class="{ 'icon-spin': spin}">
    <use :xlink:href="`#${ icon }`"></use>
  </svg>
</template>

<script>
export default {
  name: "SvgIcon",
  props: {
    path: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      required: true,
    },
    spin: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  display: inline-block;
  fill: currentColor;
  width: 1em;
  height: 1em;
}
svg.icon-spin {
  animation: icon-spin 2s infinite linear;
}
@keyframes icon-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>