// TODO: think about routes architecture: multiple pages has so called 'dashboard' root route.
// for example maybe first nav item (exchange in figma) has name 'exchange' but root route is dashboard - think of ->
// https://kauri.finance/exchange/dashboard
// https://kauri.finance/cards/dashboard
// etc

export const NAV_LINKS = [
  {
    name: 'Dashboard',
    path: '/exchange/dashboard',
    icon: 'dashboard',
  },
  {
    name: 'Wallet',
    path: '/exchange/wallet',
    icon: 'wallet',
    // visible: false,
    children: [
      {
        name: 'Balance',
        path: '/balance',
      },
      {
        name: 'Fees and limits',
        path: '/fees',
      },
    ],
  },
  {
    name: 'Invoicing',
    path: '/invoicing',
    icon: 'invoicing',
  },
];
export const NAV_LINKS_FUND = [
  {
    name: 'Fund',
    path: '/fund',
    icon: 'fund',
  },
];

export const NAV_LINKS_PROVIDERS = [
  {
    name: 'Acquiring',
    path: '/providers',
    icon: 'acquiring',
    children: [
      {
        name: 'Providers',
        path: '/dashboard',
      },
      {
        name: 'Balances',
        path: '/balances',
      },
      {
        name: 'Fees and limits',
        path: '/limits',
      },
      {
        name: 'History',
        path: '/history',
      },
    ],
  },
];

export const NAV_LINKS_SETTINGS = [
  {
    name: 'SETTINGS',
    path: '/settings',
    icon: 'settings',
    children: [
      {
        name: 'API',
        path: '/api',
      },
      {
        name: 'Security',
        path: '/security',
      },
      {
        name: 'User',
        path: '/user',
      },
    ],
  },
  {
    name: 'VERIFICATIONS',
    path: '/verification',
    icon: 'verifications',
  }
];
