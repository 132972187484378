<template>
  <div class="custom-check flex flex-wrap items-center justify-center relative">
    <template v-if="isAvailableForAccount">
      <input :checked="onChecked" type="checkbox" id="direction-two" class="checkbox hidden">
      <label
        @click.prevent="toggleThemeColor"
        for="direction-two"
        class="switch duration-300 relative inline-block w-[50px] h-[26px] rounded-[26px] mx-[5px] bg-gray-100 dark:bg-[#fff]/[.14]  cursor-pointer"
      ></label>
      <span class="fake-label-unchecked inline-block text-xs uppercase leading-none tracking-[.4px] text-white order-first transition duration-300 ease-in-out text-blue-300 dark:text-[#A6AEBF]">white</span>
      <span class="fake-label inline-block text-xs uppercase leading-none tracking-[.4px] transition duration-300 ease-in-out text-[#A6AEBF] dark:text-blue-500">dark</span>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  name: "Switcher",
  methods: {
    ...mapActions('app', ['apiGetStatistics', 'toggleThemeColor']),    
  },
  computed: {
    ...mapState('user', ['userInfo']),
    ...mapState('app', ['themeColor']),
    onChecked () {
      return this.themeColor === 'dark' ? true : false;
    },
    isAvailableForAccount () {
      return ['vlatolkach@gmail.com', 'i.malkavian+verified@gmail.com', 'aleksandrov.segrey@gmail.com', 'proskuchenko15+KF_bot_verify@gmail.com', 'proskuchenko15+KF_verify_2@gmail.com'].some(item=>item === this.userInfo?.email);
    }
  },
};
</script>
<style scoped lang="scss">

.custom-check {
  .switch:after,
  .switch-one:after {
    content: '';
    transition: all .3s;
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #2AF3F3;
    box-shadow: 0 0 10px rgba(#2AF3F3, .55);
    top: 5px;
    transform: translateX(-20px);
  }

  .checkbox:checked + .switch:after {
    transition: all .3s;
    right: 5px;
    transform: translateX(0);
  }
}
</style>