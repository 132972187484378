<template>
  <section class="max-w-full mx-auto xl:max-w-[1920px] xl:p-0">
    <div class="Application_invoice" v-if="invoice_payment_status">
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </div>
    <div class="md:flex md:justify-center md:items-start" v-else>
      <div
        class="application-nav"
        :class="{ 'application-nav--shown': mobileMenuShown }"
      >
        <Navigation @closeMenu="handleCloseMenu" />
      </div>
      <div class="application-content">
        <Header />
        <div class="application-container">
          <transition name="fade" mode="out-in">
            <router-view></router-view>
          </transition>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import Header from '@/components/_common/Header/Header';
import MobileBackButton from '@/components/_common/MobileBackButton/MobileBackButton';
import Navigation from '@/components/_common/Navigation/Navigation';

// 15 mins to logout if no activity
const INACTIVE_USER_TIME_THRESHOLD = 15 * 60 * 1000;
// 1 min throttle to not to fire events too often
const USER_ACTIVITY_THROTTLER_TIME = 1 * 60 * 1000;

export default {
  components: {
    Header,
    MobileBackButton,
    Navigation,
  },
  data: () => ({
    dataInterval: null,
    userActivityThrottlerTimeout: null,
    userActivityTimeout: null,
  }),
  methods: {
    ...mapActions('app', ['setMobileMenuShown']),
    ...mapActions('user', ['fetchUserNecessaryData', 'apiRefreshToken', 'logoutAction']),
    ...mapActions('exchange', ['apiGetExchangeRate', 'apiGetListOfCurrencies']),
    processNecessaryDataAutoRefresh() {
      this.dataInterval = setInterval(() => {
        this.fetchUserNecessaryData();
      }, 60000);
    },
    activateActivityTracker() {
      window.addEventListener('mousemove', this.userActivityThrottler);
      window.addEventListener('scroll', this.userActivityThrottler);
      window.addEventListener('keydown', this.userActivityThrottler);
      window.addEventListener('resize', this.userActivityThrottler);
    },
    deactivateActivityTracker() {
      window.removeEventListener('mousemove', this.userActivityThrottler);
      window.removeEventListener('scroll', this.userActivityThrottler);
      window.removeEventListener('keydown', this.userActivityThrottler);
      window.removeEventListener('resize', this.userActivityThrottler);
    },
    resetUserActivityTimeout() {
      clearTimeout(this.userActivityTimeout);
      this.userActivityTimeout = setTimeout(() => {
        this.userActivityThrottler();
        this.inactiveUserAction();
      }, INACTIVE_USER_TIME_THRESHOLD);
    },
    userActivityThrottler() {
      if (!this.userActivityThrottlerTimeout) {
        this.userActivityThrottlerTimeout = setTimeout(() => {
          this.resetUserActivityTimeout();
          clearTimeout(this.userActivityThrottlerTimeout);
          this.userActivityThrottlerTimeout = null;
        }, USER_ACTIVITY_THROTTLER_TIME);
      }
    },
    inactiveUserAction() {
      this.logoutAction();
      this.$router.push({ name: 'login' });
    },
    handleCloseMenu() {
      this.setMobileMenuShown(false);
    },
  },
  beforeMount() {
    this.activateActivityTracker();
  },
  beforeDestroy() {
    this.deactivateActivityTracker();
    clearTimeout(this.userActivityTimeout);
    clearTimeout(this.userActivityThrottlerTimeout);
    clearInterval(this.dataInterval);
  },
  computed: {
    ...mapGetters('invoicing', ['invoice_payment_status']),
    ...mapState('app', ['mobileMenuShown']),
  },
  mounted() {
    this.fetchUserNecessaryData();
    this.apiGetExchangeRate();
    this.apiGetListOfCurrencies();
    this.apiRefreshToken();
    this.processNecessaryDataAutoRefresh();
  },
};
</script>

<style lang="scss" scoped>
@import './Application.scss';
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
</style>

<style lang="scss">
  @import '@/components/_common/ExtraFilters/ExtraFilters.scss';
</style>
